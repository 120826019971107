@import "~antd/dist/antd.css";

.bgTop {
  background-image: url(./Components/CommonArea/Assets/goldbackground.jpg);
}

.sidebarBg {
  background-color: #000;
}
.sidebarBgImg {
  background-image: url(/src/assets/songwritersca_goldbackground.jpg);
  background-size: cover;
}
.DateRangePickerInput {
  border-radius: 0.5rem !important;
  overflow: hidden;
  border: 1px solid #cbd5e0;
  font-size: 0.875rem;

  /* width: 15rem; */
  /* font-size: 0.5rem !important; */
}

.DateInput_input {
  padding: 0.34rem !important;
  font-size: 0.875rem;
}

.DateInput_input::placeholder {
  /* padding: 0.5rem 0 0.5rem 0; */
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #4a5568;
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 2px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox-checked .checkbox-inner {
  background-color: #d69e2e !important;
  border-color: #d69e2e !important;
}

body {
  font-family: "Rubik", sans-serif;
}

progress {
  border-radius: 7px;
  width: 100%;
  height: 16px;
  margin-top: 1rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
progress::-webkit-progress-bar {
  background-color: #d1d5db;
  border-radius: 7px;
}
progress::-webkit-progress-value {
  background-color: #fbbf24;
  border-radius: 7px;
  box-shadow: 1px 1px 5px 3px #fef3c7;
}
